<template>
  <div class="d-flex flex-column gap-4">
    <v-alert type="warning" class="mb-0 mt-2" v-if="!party.cancelledAt">
      As ações que você realizar podem impactar a experiência dos clientes e a
      reputação da sua organização.
      <small class="d-block">
        <strong>Atenção:</strong> As ações realizadas não podem ser desfeitas.
      </small>
    </v-alert>
    <template v-if="!party.cancelledAt">
      <!-- <v-card outlined class="d-flex flex-column gap-2 pa-4">
        <h5>
          <v-icon left>mdi-calendar-clock</v-icon>
          Adiar
        </h5>
        <div>
          <p>Ao adiar o evento, as seguintes ações serão tomadas:</p>
          <div class="ml-3">
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Todos os ingressos vendidos serão mantidos.
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              O evento ficará com uma marcação de "Adiado".
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Todos os clientes que possuem ingressos vendidos serão notificados
              via e-mail.
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Ingressos vendidos anterior a data do adiamento poderão solicitar
              o reembolso mesmo fora do prazo.
            </p>
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn color="warning" @click="delayParty">Adiar Evento</v-btn>
        </div>
      </v-card> -->
      <v-card outlined class="d-flex flex-column gap-2 pa-4">
        <h5>
          <v-icon left>mdi-calendar-remove</v-icon>
          Cancelar
        </h5>
        <div>
          <p>Ao cancelar o evento, as seguintes ações serão tomadas:</p>
          <div class="ml-3">
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Nenhum ingresso poderá ser vendido.
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Todos os ingressos vendidos serão reembolsados.
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Não será possível editar o evento, lotes e setores.
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              O evento não aparecerá mais na lista de eventos para os clientes.
            </p>
            <p class="mb-0">
              <v-icon left small>mdi-check</v-icon>
              Todos os clientes que possuem ingressos comprados serão
              notificados via e-mail.
            </p>
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn color="error" @click="cancelParty">Cancelar Evento</v-btn>
        </div>
      </v-card>
      <PartyCancelModal :party="party" @refresh="refresh" />
    </template>
    <PartyCancelled v-else :party="party" />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import PARTY from "@/services/admin/party";

import PartyCancelModal from "@/components/admin/party/modal/PartyCancelModal.vue";
import PartyCancelled from "./modules/PartyCancelled.vue";
export default {
  components: {
    PartyCancelModal,
    PartyCancelled,
  },
  data() {
    return {};
  },
  methods: {
    delayParty() {
      this.$root.$emit("delay-party");
    },
    cancelParty() {
      this.$root.$emit("cancel-party");
    },
    refresh() {
      this.$emit("refresh");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>
