<template>
  <v-dialog v-model="dialog" max-width="500" content-class="rounded-xl">
    <v-card rounded="xl">
      <v-card-title> Benefícios do setor {{ ticketGroup?.name }} </v-card-title>

      <v-card-text class="pb-0">
        <div
          v-for="(benefit, key) in ticketGroup?.benefits || []"
          :key="key"
          class="mb-4"
        >
          <h6>
            <v-icon left class="primary--text">
              {{ getBenefitIcon(key) }}
            </v-icon>
            {{ getBenefitName(key) }}
          </h6>
          <div v-if="benefit.startAt || benefit.endAt" class="primary--text">
            <template v-if="benefit.startAt && benefit.endAt">
              A partir de {{ benefit.startAt | time }} até
              {{ benefit.endAt | time }}
            </template>
            <template v-else-if="benefit.startAt">
              A partir de {{ benefit.startAt | time }}
            </template>
            <template v-else-if="benefit.endAt">
              Até {{ benefit.endAt | time }}
            </template>
          </div>
          <div v-if="benefit.description" class="grey--text text--darken-1">
            {{ benefit.description }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TicketGroupBenefitsViewer",
  data: () => ({
    dialog: false,
    ticketGroup: null,
  }),
  methods: {
    getBenefitIcon(key) {
      const benefitsMap = {
        OPEN_BAR: "mdi-glass-mug-variant",
        OPEN_FOOD: "mdi-hamburger",
        SITTING_AREA: "mdi-seat",
        BATHROOMS: "mdi-toilet",
        AIR_CONDITIONING: "mdi-air-conditioner",
        LOCKERS: "mdi-locker",
        WIFI: "mdi-wifi",
        WAITER: "mdi-room-service",
        SAFE_SPACE: "mdi-heart-pulse",
      };
      return benefitsMap[key] || "mdi-star";
    },
    getBenefitName(key) {
      const benefitsMap = {
        OPEN_BAR: "Open Bar",
        OPEN_FOOD: "Open Food",
        SITTING_AREA: "Área de Descanso",
        BATHROOMS: "Banheiros Exclusivos",
        AIR_CONDITIONING: "Ar Condicionado",
        LOCKERS: "Armários",
        WIFI: "Wi-Fi",
        WAITER: "Garçom",
        SAFE_SPACE: "Sala de Descompressão",
      };
      return benefitsMap[key] || "Benefit";
    },
    open(ticketGroup) {
      this.ticketGroup = ticketGroup;
      this.dialog = true;
    },
  },
  filters: {
    time(value) {
      if (!value) return "";
      return new Date(`2000-01-01T${value}`).toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    this.$root.$on("show-benefits", this.open);
  },
  beforeDestroy() {
    this.$root.$off("show-benefits", this.open);
  },
};
</script>
