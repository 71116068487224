<template>
  <div class="benefits-container">
    <v-alert type="info" text dense class="mb-4">
      <b>Benefícios do Setor</b><br />
      Selecione e configure os benefícios disponíveis para este setor.
    </v-alert>

    <div v-for="benefit in benefitsList" :key="benefit.id" class="mb-4">
      <v-card
        outlined
        rounded="lg"
        :class="{
          'grey lighten-3': !activeBenefits[benefit.id] && !$vuetify.theme.dark,
          'grey darken-3': !activeBenefits[benefit.id] && $vuetify.theme.dark
        }"
        class="pa-4"
      >
        <div class="d-flex align-center mb-2">
          <v-icon
            left
            :class="{
              'primary--text': activeBenefits[benefit.id],
              'grey--text': !activeBenefits[benefit.id],
            }"
            >{{ benefit.icon }}</v-icon
          >
          <span class="font-weight-bold">{{ benefit.name }}</span>
          <v-spacer></v-spacer>
          <v-switch
            v-model="activeBenefits[benefit.id]"
            hide-details
            class="ma-0 pa-0"
          ></v-switch>
        </div>
        <v-textarea
          v-model="benefits[benefit.id].description"
          label="Descrição"
          rows="2"
          dense
          outlined
          hide-details="auto"
          :disabled="!activeBenefits[benefit.id]"
        ></v-textarea>

        <div v-if="benefit.hasTime" class="d-flex mt-2 gap-4">
          <v-text-field
            v-model="benefits[benefit.id].startAt"
            label="Horário Início"
            type="time"
            dense
            outlined
            hide-details="auto"
            :disabled="!activeBenefits[benefit.id]"
          ></v-text-field>

          <v-text-field
            v-model="benefits[benefit.id].endAt"
            label="Horário Fim"
            type="time"
            dense
            outlined
            hide-details="auto"
            :disabled="!activeBenefits[benefit.id]"
          ></v-text-field>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
const defaultBenefits = {
  OPEN_BAR: {
    description: "",
    startAt: null,
    endAt: null,
  },
  OPEN_FOOD: {
    description: "",
    startAt: null,
    endAt: null,
  },
  SITTING_AREA: {
    description: "",
  },
  BATHROOMS: {
    description: "",
  },
  AIR_CONDITIONING: {
    description: "",
  },
  LOCKERS: {
    description: "",
  },
  WIFI: {
    description: "",
  },
  WAITER: {
    description: "",
  },
  SAFE_SPACE: {
    description: "",
  },
};

const benefitsList = [
  {
    id: "OPEN_BAR",
    name: "Open Bar",
    icon: "mdi-glass-mug-variant",
    hasTime: true,
  },
  {
    id: "OPEN_FOOD",
    name: "Open Food",
    icon: "mdi-hamburger",
    hasTime: true,
  },
  {
    id: "SITTING_AREA",
    name: "Área para Sentar",
    icon: "mdi-seat",
    hasTime: false,
  },
  {
    id: "BATHROOMS",
    name: "Banheiros Exclusivos",
    icon: "mdi-toilet",
    hasTime: false,
  },
  {
    id: "AIR_CONDITIONING",
    name: "Ar Condicionado",
    icon: "mdi-air-conditioner",
    hasTime: false,
  },
  {
    id: "LOCKERS",
    name: "Armários",
    icon: "mdi-locker",
    hasTime: false,
  },
  {
    id: "WIFI",
    name: "Wi-Fi",
    icon: "mdi-wifi",
    hasTime: false,
  },
  {
    id: "WAITER",
    name: "Garçom",
    icon: "mdi-room-service",
    hasTime: false,
  },
  {
    id: "SAFE_SPACE",
    name: "Sala de Descompressão",
    icon: "mdi-heart-pulse",
    hasTime: false,
  },
];

export default {
  name: "TicketGroupBenefits",
  props: {
    initialBenefits: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    benefits: { ...defaultBenefits },
    activeBenefits: {},
    benefitsList,
  }),
  watch: {
    initialBenefits: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setBenefitsData(newValue);
        }
      },
    },
  },
  methods: {
    getBenefitsData() {
      const result = {};
      Object.keys(this.activeBenefits).forEach((key) => {
        if (this.activeBenefits[key]) {
          result[key] = this.benefits[key];
        }
      });
      return Object.keys(result).length > 0 ? result : null;
    },
    setBenefitsData(data) {
      this.benefits = { ...defaultBenefits };

      this.activeBenefits = Object.keys(defaultBenefits).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      if (data) {
        Object.keys(data).forEach((key) => {
          this.benefits[key] = {
            ...defaultBenefits[key],
            ...data[key],
          };
          this.activeBenefits[key] = true;
        });
      }
    },
  },
};
</script>

<style scoped>
.gap-4 {
  gap: 16px;
}
</style>
