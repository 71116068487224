<template>
  <div>
    <template v-if="!complete">
      <v-alert v-if="loadingTicketGroups" class="mb-0" type="info" text>
        Carregando lotes, por favor aguarde...
      </v-alert>
      <!-- <v-alert
        v-else-if="!ticketGroups.length"
        class="mb-0"
        type="warning"
        text
      >
        Você não possui permissão para emitir nenhum lote como cortesia.
      </v-alert> -->
      <template v-else>
        <h5>Link Promocional</h5>

        <v-form v-model="valid" @submit.prevent>
          <v-row dense class="mx-0">
            <v-col cols="12">
              <v-text-field
                v-model="data.name"
                label="Nome"
                outlined
                :disabled="loading"
                dense
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </v-col>

            <v-col cols="12">
              <base-editor
                label="Descrição"
                v-model="data.description"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-if="!editMode"
                v-model="data.ticketBlock"
                :items="ticketBlocksList"
                item-text="text"
                return-object
                label="Lote"
                dense
                outlined
                :disabled="loading"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.maxTickets"
                label="Quantidade máxima de ingressos"
                hint="O lote deve ter estoque suficiente para atender ao máximo de ingressos"
                type="number"
                outlined
                :disabled="loading"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => v >= 1 || 'Mínimo de 1',
                ]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.maxPerUser"
                label="Máximo de ingressos por usuário"
                type="number"
                outlined
                :disabled="loading"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => v >= 1 || 'Mínimo de 1',
                ]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="data.maxDate"
                label="Data máxima de emissão"
                type="datetime-local"
                outlined
                :disabled="loading"
                dense
                hint="Deixe em branco para não limitar a data"
                :min="new Date().toISOString().split('T')[0]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-alert v-if="error" class="mb-0 mt-4" type="error" text>
          {{ error }}
        </v-alert>
      </template>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-center">
        <h5 class="mb-2 text-center" v-if="!editMode">
          {{ loading ? "Criando link promocional" : "Link promocional criado" }}
        </h5>

        <div class="my-6">
          <v-progress-circular
            v-if="loading && !editMode"
            indeterminate
            color="primary"
            size="50"
          />

          <v-btn
            v-else-if="courtesyPromotional && !editMode"
            color="primary"
            large
            @click="copyLink"
            :disabled="copied || loading"
          >
            <v-icon left>mdi-{{ copied ? "check" : "content-copy" }}</v-icon>
            {{ copied ? "Link copiado" : "Copiar link" }}
          </v-btn>
        </div>
      </div>

      <v-btn
        v-if="!editMode"
        block
        text
        :disabled="loading"
        @click="$emit('close', true)"
      >
        Fechar
      </v-btn>
    </template>
    <div v-if="!complete" class="d-flex justify-space-between mt-3">
      <v-btn text @click="editMode ? $emit('close') : $emit('prev')">
        Voltar
      </v-btn>
      <v-btn
        v-if="editMode"
        color="success"
        @click="send"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Salvar
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="send"
        :disabled="loading || !valid"
        :loading="loading"
      >
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import COURTESY_PROMOTIONAL from "@/services/admin/courtesyPromotional";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: { PhoneInput },
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
    loadingTicketGroups: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    promotionalData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      complete: false,
      error: null,
      success: false,
      valid: false,
      copied: false,
      courtesyPromotional: null,
      data: {
        name: "",
        description: "",
        ticketBlock: null,
        maxTickets: 1,
        maxPerUser: 1,
        maxDate: null,
        active: true,
      },
    };
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        this.error = null;

        if (!this.editMode) {
          this.complete = true;
          this.$emit("next");
        }

        const data = {
          name: this.data.name,
          description: this.data.description,
          ticketBlockId: this.editMode
            ? this.promotionalData.ticketBlockId
            : this.data.ticketBlock.id,
          maxTickets: parseInt(this.data.maxTickets),
          maxPerUser: parseInt(this.data.maxPerUser),
          maxDate: moment(this.data.maxDate).toISOString(),
        };

        if (this.editMode) {
          await COURTESY_PROMOTIONAL.update(
            this.party.organizationId,
            this.party.id,
            this.promotionalData.id,
            data
          );
          this.$emit("close", true);
        } else {
          const { courtesyPromotional } = await COURTESY_PROMOTIONAL.create(
            this.party.organizationId,
            this.party.id,
            data
          );
          this.courtesyPromotional = courtesyPromotional;
        }

        this.success = true;
      } catch (error) {
        this.error = error.message || "Erro ao emitir cortesias";
        this.complete = false;
      } finally {
        this.loading = false;
      }
    },
    copyLink() {
      var url = `${window.location.origin}/redeem/courtesy/${this.party.slug}/${this.courtesyPromotional.id}`;
      navigator.clipboard.writeText(url);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
  watch: {},
  computed: {
    ticketBlocksList() {
      const ticketBlocks = [];
      this.ticketGroups
        .filter(({ tableGroupId }) => !tableGroupId)
        .forEach((ticketGroup) => {
          ticketGroup.TicketBlock.forEach((ticketBlock) => {
            ticketBlocks.push({
              text: `${ticketGroup.name} - ${ticketBlock.name}`,
              id: ticketBlock.id,
            });
          });
        });

      return ticketBlocks;
    },
  },
  mounted() {
    if (this.editMode && this.promotionalData) {
      this.data = {
        name: this.promotionalData.name,
        description: this.promotionalData.description,
        ticketBlock: this.ticketBlocksList.find(
          (b) => b.id === this.promotionalData.ticketBlockId
        ),
        maxTickets: this.promotionalData.maxTickets,
        maxPerUser: this.promotionalData.maxPerUser,
        maxDate: moment(this.promotionalData.maxDate).format(
          "YYYY-MM-DDTHH:mm"
        ),
        active: this.promotionalData.active,
      };
    } else {
      this.data.maxDate = moment(this.party.date).format("YYYY-MM-DDTHH:mm");
    }
  },
  watch: {
    promotionalData: {
      handler(newVal) {
        this.complete = false;

        if (this.editMode && newVal) {
          this.data = {
            ...this.data,
            name: newVal.name,
            description: newVal.description,
            ticketBlock: this.ticketBlocksList.find(
              (b) => b.id === newVal.ticketBlockId
            ),
            maxTickets: newVal.maxTickets,
            maxPerUser: newVal.maxPerUser,
            maxDate: moment(newVal.maxDate).format("YYYY-MM-DDTHH:mm"),
            active: newVal.active,
          };
        } else {
          this.data = {
            ...this.data,
            name: "",
            description: "",
            ticketBlock: null,
            maxTickets: 1,
            maxPerUser: 1,
            maxDate: moment(this.party.date).format("YYYY-MM-DDTHH:mm"),
            active: true,
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style></style>
