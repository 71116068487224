var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_vm._v(" Vincular POS por Número de Série ")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.loading,"search-input":_vm.search,"hide-no-data":"","hide-details":"","item-text":"Device.serialNumber","label":"Número de Série","placeholder":"Digite o número de série","return-object":"","outlined":"","filter":_vm.filter},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(_vm.session.Device?.Model?.image)?_c('v-img',{attrs:{"src":(_vm.session.Device?.acquirer &&
                _vm.session.Device?.Model?.imageVariants?.[
                  _vm.session?.Device?.acquirer
                ]) ||
              _vm.session.Device?.Model?.image,"max-height":"30","max-width":"30"}}):_vm._e(),_vm._v(" "+_vm._s(data.item.Device.serialNumber)+" ")]}},{key:"item",fn:function({ item: session }){return [_c('v-list-item-avatar',[(session.Device.Model?.image)?_c('v-img',{attrs:{"src":(session.Device?.acquirer &&
                  session.Device?.Model?.imageVariants?.[
                    session?.Device?.acquirer
                  ]) ||
                session.Device?.Model?.image,"max-height":"40","max-width":"40"}}):_vm._e()],1),_c('v-list-item-content',[_c('span',[_vm._v(" "+_vm._s(session.Device?.Model?.model)+" • "+_vm._s(session.Device?.Model?.brand)+" ")]),_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(session.Device?.serialNumber))])])]}}]),model:{value:(_vm.session),callback:function ($$v) {_vm.session=$$v},expression:"session"}})],1),_c('v-card-actions',{staticClass:"justify-space-between"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("cancelar")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.session},on:{"click":_vm.connect}},[_vm._v(" Continuar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }