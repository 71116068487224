import http from "@/http-common";

const create = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/coupons`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/coupons`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const update = async (orgId, partyId, couponId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/coupons/${couponId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};


const getPayment = async (orgId, partyId, id) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/coupons/${id}/payments`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  create,
  getAll,
  getPayment,
  update,
};
