<template>
  <div>
    <p class="font-weight-bold mb-0 lh-1">{{ title }}</p>
    <small v-if="subtitle" class="text-12 text--secondary lh-1 mb-1">
      {{ subtitle }}
    </small>
    <v-chip-group center-active show-arrows>
      <v-alert
        v-for="(benefit, key) in ticketGroup.benefits"
        :key="key"
        color="primary"
        dense
        text
        :style="disabled ? null : 'cursor: pointer'"
        class="py-1 text-12 px-2 mr-2 mb-0"
        @click="disabled ? null : viewBenefits()"
      >
        <v-icon x-small left color="primary">
          {{ getBenefitIcon(key) }}
        </v-icon>
        <span>{{ getBenefitName(key) }}</span>
      </v-alert>
    </v-chip-group>
  </div>
</template>

<script>
export default {
  props: {
    ticketGroup: {
      type: Object,
      required: true,
    },
    title: {
      type: String || null,
      default: "Benefícios:",
    },
    subtitle: {
      type: String || null,
      default: "Clique para ver detalhes",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewBenefits() {
      this.$root.$emit("show-benefits", this.ticketGroup);
    },
    getBenefitIcon(key) {
      const benefitsMap = {
        OPEN_BAR: "mdi-glass-mug-variant",
        OPEN_FOOD: "mdi-hamburger",
        SITTING_AREA: "mdi-seat",
        BATHROOMS: "mdi-toilet",
        AIR_CONDITIONING: "mdi-air-conditioner",
        LOCKERS: "mdi-locker",
        WIFI: "mdi-wifi",
        WAITER: "mdi-room-service",
        SAFE_SPACE: "mdi-heart-pulse",
      };
      return benefitsMap[key] || "mdi-star";
    },
    getBenefitName(key) {
      const benefitsMap = {
        OPEN_BAR: "Open Bar",
        OPEN_FOOD: "Open Food",
        SITTING_AREA: "Área de Descanso",
        BATHROOMS: "Banheiros Exclusivos",
        AIR_CONDITIONING: "Ar Condicionado",
        LOCKERS: "Armários",
        WIFI: "Wi-Fi",
        WAITER: "Garçom",
        SAFE_SPACE: "Sala de Descompressão",
      };
      return benefitsMap[key] || "Benefício";
    },
  },
};
</script>
