<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" sm="7">
        <template v-if="url">
          <v-img
            :src="url"
            aspect-ratio="1"
            contain
            class="rounded"
            height="350px"
          ></v-img>
          <span class="caption text--disabled text-center d-block">
            {{ mapDescription }}
          </span>
        </template>

        <v-alert v-else color="info" text class="mb-0">
          <p>
            O mapa aparecerá na página de vendas do online do ingresso.
            <!-- e nos PDVs. -->
          </p>
          <p class="mb-0">
            Formato recomendado: proporção 4:3 e mínimo de 800x600px, peso
            máximo de 1MB.
          </p>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="5">
        <file-drop
          @change="loadFile"
          :disabled="!!loading"
          accept=".png,.jpg,.jpeg,.webp,"
          label="Arraste o arquivo do mapa aqui"
        />
        <p v-if="url" class="px-2 text-center text-12 text--secondary">
          Formato recomendado: proporção 4:3 e mínimo de 800x600px, peso máximo
          de 1MB.
        </p>

        <v-text-field
          v-if="url"
          :disabled="!!loading"
          v-model="mapDescription"
          label="Descrição do mapa"
          outlined
          dense
          counter="100"
          clearable
          :rules="[(v) => !v || v.length <= 100 || 'Máximo de 100 caracteres']"
        />
        
        <v-btn
          v-if="url"
          @click="removeMap"
          color="error"
          text
          class="mt-2"
          :disabled="loading"
          :loading="loading"
        >
          <v-icon left>mdi-delete</v-icon>
          Remover mapa
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-btn
        @click="saveMap"
        color="success"
        :disabled="
          loading || (!file && party.mapDescription === mapDescription)
        "
        :loading="loading"
      >
        Salvar
      </v-btn>
    </div>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";

import FileDrop from "../../global/FileDrop.vue";
export default {
  components: { FileDrop },
  mounted() {},
  data: () => ({
    mapURL: null,
    file: null,
    mapDescription: "",
    loading: false,
  }),
  watch: {
    party: {
      handler() {
        this.mapURL = this.party.imageMap;
        this.mapDescription = `${this.party.mapDescription || ""}`;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async saveMap() {
      this.loading = true;
      try {
        if (this.file)
          await PARTY.staticMap(
            this.party.organizationId,
            this.party.id,
            this.file,
            this.mapDescription
          );

        if (this.mapDescription !== this.party.mapDescription)
          await PARTY.update({
            id: this.party.id,
            organizationId: this.party.organizationId,
            mapDescription: this.mapDescription,
          });

        this.$emit("refresh");
        this.file = null;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    loadFile(file) {
      this.file = file;
      this.saveMap();
    },
    async removeMap() {
      this.loading = true;
      try {
        await PARTY.deleteMap(
          this.party.organizationId,
          this.party.id
        );

        this.mapURL = null;
        this.mapDescription = "";
        this.file = null;
        this.$emit("refresh");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    url() {
      if (this.file) return URL.createObjectURL(this.file);

      return this.mapURL || this.party.imageMap;
    },
  },

  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
