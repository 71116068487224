<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2 mb-3">
      <h6 class="mb-0">Cupons</h6>
      <v-btn
        @click="addCoupon"
        color="primary"
        :small="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">Cupom</template>
      </v-btn>
    </div>

    <v-expansion-panels focusable v-model="expanded">
      <coupon-management-item
        v-for="(coupon, i) in sortedCoupons"
        :key="coupon.id"
        :coupon="coupon"
        :expanded="expanded === i"
        :party="party"
      />
    </v-expansion-panels>

    <coupon-modal :party="party" @refresh="getCoupons" />
    <edit-coupon-modal :party="party" @refresh="getCoupons" />
  </div>
</template>

<script>
import COUPON from "@/services/admin/party/coupon";
import CouponModal from "./coupon/CouponModal.vue";
import CouponManagementItem from "./coupon/CouponManagementItem.vue";
import EditCouponModal from "./coupon/EditCouponModal.vue";

export default {
  components: { CouponModal, CouponManagementItem, EditCouponModal },
  data: () => ({
    coupons: [],
    expanded: null,
    loading: false,
  }),
  methods: {
    async getCoupons() {
      try {
        const { coupons } = await COUPON.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.coupons = coupons;
      } catch (error) {
        console.error(error);
      }
    },

    addCoupon() {
      this.$root.$emit("coupon-modal");
    },
  },
  computed: {
    sortedCoupons() {
      return this.coupons.sort((a, b) => {
        if (a.validUntil && b.validUntil) return b.validUntil - a.validUntil;

        if (a.validUntil) return 1;
        else return -1;
      });
    },
  },
  mounted() {
    this.getCoupons();
  },
  props: {
    party: Object,
  },
};
</script>

<style></style>
