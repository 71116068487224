<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="pb-0 pt-3 px-6">
        <span class="text-16">
          {{ coupon?.id ? "Editar cupom" : "Adicionar cupom" }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-stepper
          v-model="step"
          class="elevation-0"
          :non-linear="!!coupon?.id"
        >
          <v-stepper-header class="elevation-0 mb-2" style="height: 50px">
            <v-stepper-step :complete="step > 1" :step="1" class="py-0">
              Detalhes
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="step > 2" :step="2" class="py-0">
              Regras
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <coupon-details-form
                v-model="coupon"
                :valid.sync="validDetails"
              />
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <coupon-rules-form
                v-model="coupon"
                :party="party"
                :valid.sync="validRules"
                :ticketGroups="ticketGroups"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1">
        <template v-if="step === 1">
          <v-switch
            class="mt-0"
            v-model="coupon.active"
            label="Ativo"
            :messages="[coupon.active ? 'Cupom ativo' : 'Cupom inativo']"
          />
          <v-spacer />
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            color="primary"
            :disabled="!validDetails || loading"
            @click="step++"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === 2">
          <v-btn text :disabled="loading" @click="step--"> Voltar </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="save"
          >
            Salvar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";
import COUPON from "@/services/admin/party/coupon";

import CouponDetailsForm from "./components/CouponDetailsForm.vue";
import CouponRulesForm from "./components/CouponRulesForm.vue";
import CouponCodesForm from "./components/CouponCodesForm.vue";
import moment from "moment";

const defaultCoupon = () => ({
  id: null,
  name: "",
  description: "",
  validUntil: null,
  maxTickets: null,
  showOnline: false,
  active: true,
  rules: [], 
});

export default {
  components: { CouponDetailsForm, CouponRulesForm, CouponCodesForm },
  data: () => ({
    isOpen: false,
    loading: false,
    validDetails: false,
    validRules: false,
    error: false,
    step: 1,
    coupon: defaultCoupon(),
    ticketGroups: [],
  }),

  methods: {
    open(data = {}) {
      this.isOpen = true;
      const formattedData = {
        ...data,
        validUntil: data.validUntil ? moment(data.validUntil).format('YYYY-MM-DDTHH:mm') : null
      };
      if (Array.isArray(formattedData.Rules)) {
        formattedData.rules = formattedData.Rules.map((rule) => ({
          partyId: rule.partyId,
          ticketGroupId: rule.ticketGroupId,
          ticketBlockId: rule.ticketBlockId,
          discount: rule.discount,
        }));
      }

      this.coupon = Object.assign(defaultCoupon(), formattedData);
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.coupon = defaultCoupon();
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
    async getTicketGroups() {
      this.loading = true;
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id,
          { childrenParty: true }
        );
        this.ticketGroups = response.ticketGroups;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;
      try {
        const validUntil = moment(this.coupon.validUntil).toISOString();
        const { id, rules, ...couponData } = this.coupon;

        const response = await COUPON.update(
          this.party.organizationId,
          this.party.id,
          id,
          {
            ...couponData,
            validUntil,
            maxTickets: couponData.maxTickets ? parseInt(couponData.maxTickets) : null,
            rules: rules
          }
        );

        this.$emit("refresh", response);
        this.close();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {},

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("edit-coupon-modal", this.open);
    this.getTicketGroups();
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
};
</script>

<style></style>
