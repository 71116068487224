<template>
  <div class="pa-1">
    <h6 class="text-overline">Desconto para o evento</h6>
    <v-card class="mb-2 pa-3 px-4 rounded-lg">
      <div class="mb-1 d-flex align-center justify-space-between">
        <h6 class="">
          {{ party.name }}
        </h6>
        <v-chip label small> Aplicado em todos os ingressos </v-chip>
      </div>

      <v-row dense class="px-1">
        <v-col cols="6">
          <v-text-field
            label="Reais"
            v-model="rules.party.fees.fixed"
            type="number"
            hide-details
            prefix="R$"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Porcentagem"
            v-model="rules.party.fees.percentage"
            type="number"
            hide-details
            suffix="%"
            :rules="inputRules.percentage"
            min="0"
            max="100"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <h6 class="text-overline">Desconto por setor</h6>

    <v-expansion-panels class="px-1">
      <v-expansion-panel
        v-for="ticketGroup in ticketGroups"
        :key="ticketGroup.id"
      >
        <v-expansion-panel-header>
          <div>
            <b class="flex-grow-1">{{ ticketGroup.name }}</b>
            <v-chip
              v-if="ticketGroup.Party.id !== party.id"
              class="ml-2"
              small
              label
            >
              {{ ticketGroup.Party.name }}
            </v-chip>
          </div>
          <div class="flex-grow-0">
            <v-chip
              v-if="
                rules.party.fees.fixed ||
                rules.ticketGroups[ticketGroup.id].fixed
              "
              small
              label
              class="mr-1"
              :color="
                rules.ticketGroups[ticketGroup.id].fixed ? 'primary' : undefined
              "
            >
              {{
                (rules.ticketGroups[ticketGroup.id].fixed ||
                  rules.party.fees.fixed) | currency(true)
              }}
            </v-chip>
            <v-chip
              small
              label
              class="mr-2"
              :color="
                rules.ticketGroups[ticketGroup.id].percentage
                  ? 'primary'
                  : undefined
              "
            >
              {{
                rules.ticketGroups[ticketGroup.id].percentage ||
                rules.party.fees.percentage ||
                0
              }}%
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense class="mx-0">
            <v-col cols="6">
              <v-text-field
                label="Desconto em reais"
                v-model="rules.ticketGroups[ticketGroup.id].fixed"
                type="number"
                min="0"
                :rules="inputRules.fixed"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Desconto em porcentagem"
                v-model="rules.ticketGroups[ticketGroup.id].percentage"
                type="number"
                min="0"
                max="100"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <h6 class="text-overline">Desconto por lote</h6>

          <div class="d-flex flex-column gap-2">
            <v-card
              v-for="ticketBlock in ticketGroup.TicketBlock"
              :key="ticketBlock.id"
              outlined
              class="pa-2"
            >
              <div class="d-flex flex-grow-1">
                <b class="text-start flex-grow-1">{{ ticketBlock.name }}</b>
                <v-chip
                  v-if="
                    rules.ticketBlocks[ticketBlock.id].fixed ||
                    rules.ticketGroups[ticketGroup.id].fixed
                  "
                  small
                  label
                  class="mr-1"
                  :color="
                    rules.ticketBlocks[ticketBlock.id].fixed
                      ? 'primary'
                      : undefined
                  "
                >
                  {{
                    (rules.ticketBlocks[ticketBlock.id].fixed ||
                      rules.ticketGroups[ticketGroup.id].fixed ||
                      rules.party.fees.fixed) | currency(true)
                  }}
                </v-chip>
                <v-chip
                  small
                  label
                  :color="
                    rules.ticketBlocks[ticketBlock.id].percentage
                      ? 'primary'
                      : undefined
                  "
                >
                  {{
                    rules.ticketBlocks[ticketBlock.id].percentage ||
                    rules.ticketGroups[ticketGroup.id].percentage ||
                    rules.party.fees.percentage ||
                    0
                  }}%
                </v-chip>
              </div>
              {{ ticketBlock.price | currency(true) }}
              <v-row dense class="mx-0">
                <v-col cols="6">
                  <v-text-field
                    label="Desconto em reais"
                    v-model="rules.ticketBlocks[ticketBlock.id].fixed"
                    type="number"
                    min="0"
                    :rules="inputRules.fixed"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Desconto em porcentagem"
                    v-model="rules.ticketBlocks[ticketBlock.id].percentage"
                    type="number"
                    min="0"
                    max="100"
                    outlined
                    :rules="inputRules.percentage"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
const defaultData = () => ({});
export default {
  data: () => ({
    rules: {
      party: { fees: { fixed: null, percentage: null } },
      ticketGroups: {},
      ticketBlocks: {},
    },

    inputRules: {
      percentage: [
        (v) => !v || v > 0 || "Valor deve ser maior que 0",
        (v) => !v || v < 100 || "Valor deve ser menor que 100",
      ],
      fixed: [(v) => !v || v > 0 || "Valor deve ser maior que 0"],
    },
  }),

  methods: {
    seedRules() {
      this.rules.ticketGroups = {};
      this.rules.ticketBlocks = {};
      this.ticketGroups.forEach((ticketGroup) => {
        this.$set(this.rules.ticketGroups, ticketGroup.id, {
          fixed: null,
          percentage: null,
        });
        ticketGroup.TicketBlock.forEach((ticketBlock) => {
          this.$set(this.rules.ticketBlocks, ticketBlock.id, {
            fixed: null,
            percentage: null,
          });
        });
      });
    },
    formatRules() {
      const result = [];

      // Party-level rule
      if (this.rules.party.fees.fixed || this.rules.party.fees.percentage) {
        result.push({
          partyId: this.party.id,
          ticketGroupId: null,
          ticketBlockId: null,
          discount: {
            fixed: this.rules.party.fees.fixed || 0,
            percentage: this.rules.party.fees.percentage || 0,
          },
        });
      }

      // TicketGroup-level rules
      for (const tgId in this.rules.ticketGroups) {
        if (
          this.rules.ticketGroups[tgId].fixed ||
          this.rules.ticketGroups[tgId].percentage
        ) {
          result.push({
            partyId: this.party.id,
            ticketGroupId: tgId,
            ticketBlockId: null,
            discount: {
              fixed: this.rules.ticketGroups[tgId].fixed || 0,
              percentage: this.rules.ticketGroups[tgId].percentage || 0,
            },
          });
        }
      }

      // TicketBlock-level rules
      for (const tbId in this.rules.ticketBlocks) {
        if (
          this.rules.ticketBlocks[tbId].fixed ||
          this.rules.ticketBlocks[tbId].percentage
        ) {
          const tg = this.ticketGroups.find((group) =>
            group.TicketBlock.find((block) => block.id == tbId)
          );
          result.push({
            partyId: this.party.id,
            ticketGroupId: tg ? tg.id : null,
            ticketBlockId: tbId,
            discount: {
              fixed: this.rules.ticketBlocks[tbId].fixed || 0,
              percentage: this.rules.ticketBlocks[tbId].percentage || 0,
            },
          });
        }
      }

      return result;
    },
    populateFormFromValueRules() {
      this.seedRules(); // primeiro, reset

      if (Array.isArray(this.value.rules)) {
        this.value.rules.forEach((rule) => {
          const { ticketGroupId, ticketBlockId, discount } = rule;
          const { fixed, percentage } = discount;

          if (!ticketGroupId && !ticketBlockId) {
            // party-level
            this.rules.party.fees.fixed = fixed;
            this.rules.party.fees.percentage = percentage;
          } else if (ticketGroupId && !ticketBlockId) {
            // group-level
            if (!this.rules.ticketGroups[ticketGroupId]) {
              this.$set(this.rules.ticketGroups, ticketGroupId, {
                fixed: null,
                percentage: null,
              });
            }
            this.rules.ticketGroups[ticketGroupId].fixed = fixed;
            this.rules.ticketGroups[ticketGroupId].percentage = percentage;
          } else if (ticketBlockId) {
            // block-level
            if (!this.rules.ticketBlocks[ticketBlockId]) {
              this.$set(this.rules.ticketBlocks, ticketBlockId, {
                fixed: null,
                percentage: null,
              });
            }
            this.rules.ticketBlocks[ticketBlockId].fixed = fixed;
            this.rules.ticketBlocks[ticketBlockId].percentage = percentage;
          }
        });
      }
    },
    verifyRules() {
      if (this.rules.party.fees.fixed || this.rules.party.fees.percentage)
        return true;

      for (const key in this.rules.ticketGroups) {
        if (
          this.rules.ticketGroups[key].fixed ||
          this.rules.ticketGroups[key].percentage
        )
          return true;
      }

      for (const key in this.rules.ticketBlocks) {
        if (
          this.rules.ticketBlocks[key].fixed ||
          this.rules.ticketBlocks[key].percentage
        )
          return true;
      }

      return false;
    },
  },
  watch: {
    rules: {
      handler() {
        // Evita loops internos
        if (this.internalUpdate) return;

        const valid = this.verifyRules();
        const newRules = valid ? this.formatRules() : [];

        // Só emite se houver diferença do atual
        const isDifferent =
          JSON.stringify(newRules) !== JSON.stringify(this.value.rules);

        if (isDifferent) {
          this.internalUpdate = true;
          this.$emit("update:valid", valid);
          this.$emit("input", {
            ...this.value,
            rules: newRules,
          });
          this.internalUpdate = false;
        } else {
          // Mesmo assim atualiza a validade
          this.$emit("update:valid", valid);
        }
      },
      deep: true,
    },

    'value.rules': {
      handler() {
        // Quando o parent muda as rules, atualiza internamente
        this.populateFormFromValueRules();
      },
    },

    // Se os ticketGroups mudarem, precisamos repopular as regras
    ticketGroups() {
      this.populateFormFromValueRules();
    },
  },

  props: {
    value: {
      type: Object,
      default: () => defaultData(),
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.populateFormFromValueRules();
  },
};
</script>

<style></style>
