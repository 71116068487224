<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="close" scrollable>
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-16 pb-1 justify-space-between">
        <span class="d-flex align-center gap-1 lh-1">
          Compra
          <base-copier type="chip" :value="payment?.id || ''" hide-icon>
            <template v-slot:default="{ copy, icon }">
              <v-chip
                @click="copy"
                x-small
                label
                class="text-overline font-weight-medium"
              >
                #{{ payment?.id | shortId }}
                <v-icon right x-small>{{ icon }}</v-icon>
              </v-chip>
            </template>
          </base-copier>
        </span>
        <v-chip
          v-if="payment"
          small
          :loading="loading"
          :color="PAYMENT.ticketStatus[payment?.status]?.color"
        >
          <v-icon left small>
            {{ PAYMENT.ticketStatus[payment?.status]?.icon }}
          </v-icon>
          {{ PAYMENT.ticketStatus[payment?.status]?.text }}
        </v-chip>
        <v-skeleton-loader
          v-else
          x-small
          width="100"
          height="22"
          type="card"
          class="rounded-xl"
        />
      </v-card-title>
      <v-card-text v-if="payment" class="d-flex flex-column gap-y-3 pt-2">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-start">Chave</th>
                <th class="text-start">Valor</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(value, key) in { ...payment, ...payment?.extraData }"
              >
                <tr v-if="!keys[key]" :key="key">
                  <td>{{ key }}</td>
                  <td @click="copyContent" style="cursor: pointer">
                    <pre>{{ value }}</pre>
                  </td>
                </tr>
                <tr v-else-if="!keys[key]?.hide" :key="`${key}-${key}`">
                  <td>{{ keys[key]?.label || key }}</td>
                  <td @click="copyContent" style="cursor: ">
                    <template v-if="keys[key]?.filter">
                      {{
                        applyFilter(
                          value,
                          keys[key]?.filter,
                          keys[key]?.filterParams
                        )
                      }}
                    </template>
                    <template v-else>
                      {{ value }}
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" small text block :disabled="loading">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";

import GuestTicketTransfer from "./GuestTicketTransferDetails.vue";

export default {
  props: {},
  components: { GuestTicketTransfer },
  data: () => ({
    dialog: false,
    loading: false,
    ticketResent: false,
    payment: null,
    paymentId: null,
    PAYMENT,

    keys: {
      amount: {
        label: "Valor",
        filter: "currency",
      },
      discount: {
        label: "Desconto",
        filter: "currency",
      },
      clientFee: {
        label: "Taxa de serviço",
        filter: "currency",
      },
      createdAt: {
        label: "Data",
        filter: "date",
        filterParams: ["DD/MM/YYYY HH:mm:ss"],
      },
      updatedAt: {
        label: "Data de atualização",
        filter: "date",
        filterParams: ["DD/MM/YYYY HH:mm:ss"],
      },
      refundedAt: {
        label: "Data de reembolso",
        filter: "date",
        filterParams: ["DD/MM/YYYY HH:mm:ss"],
      },
      validity: {
        label: "Validade",
        filter: "date",
        filterParams: ["DD/MM/YYYY HH:mm:ss"],
      },
      payedAt: {
        label: "Data de pagamento",
        filter: "date",
        filterParams: ["DD/MM/YYYY HH:mm:ss"],
      },
      platformFee: {
        label: "Taxa de plataforma",
        filter: "currency",
      },
      paymentMethod: {
        label: "Método de pagamento",
      },
      paymentType: {
        label: "Forma de pagamento",
      },
      status: {
        label: "Status",
      },
      PosSession: {
        hide: true,
      },
      MembershipFiliation: {
        hide: true,
      },
      Ticket: {
        hide: true,
      },
      integrationId: {
        label: "ID da integração",
      },
      integrationFee: {
        label: "Taxa de integração",
        filter: "currency",
      },
      installments: {
        label: "Parcelas",
      },
      installmentsFee: {
        label: "Taxa de parcelamento",
        filter: "currency",
      },
      extraData: {
        hide: true,
      },
      bin: {
        label: "Bin",
      },
      date: {
        label: "Data",
      },
      time: {
        label: "Hora",
      },
      holder: {
        label: "Titular",
      },
      transactionId: {
        label: "ID da transação",
      },
      transactionCode: {
        label: "Código da transação",
      },
      terminalSerialNumber: {
        label: "Número de série do POS",
      },
      availableBalance: {
        label: "Saldo disponível",
      },
      extendedHolderName: {
        label: "Nome do titular",
      },
      userReference: {
        label: "Referência do usuário",
      },
      chargeback: {
        label: "Contestado",
        filter: "boolean",
      },
    },
  }),
  methods: {
    open(payment) {
      if (this.dialog) return;
      this.dialog = true;
      this.payment = payment;
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.payment = null;
      // this.$emit("refresh");
    },
    copyContent(event) {
      const target = event.target;
      window.navigator.clipboard.writeText(
        target.innerText || target.textContent
      );
      target.classList.add("success--text");
      setTimeout(() => {
        target.classList.remove("success--text");
      }, 1000);
    },
    applyFilter(value, filter, params = []) {
      return this.$options.filters[filter](value, ...params);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  filters: {
    boolean(value) {
      return value ? "Sim" : "Não";
    },
  },
  mounted() {
    this.$root.$on("payment-full-info", this.open);
  },
};
</script>

<style></style>
