var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"benefits-container"},[_c('v-alert',{staticClass:"mb-4",attrs:{"type":"info","text":"","dense":""}},[_c('b',[_vm._v("Benefícios do Setor")]),_c('br'),_vm._v(" Selecione e configure os benefícios disponíveis para este setor. ")]),_vm._l((_vm.benefitsList),function(benefit){return _c('div',{key:benefit.id,staticClass:"mb-4"},[_c('v-card',{staticClass:"pa-4",class:{
        'grey lighten-3': !_vm.activeBenefits[benefit.id] && !_vm.$vuetify.theme.dark,
        'grey darken-3': !_vm.activeBenefits[benefit.id] && _vm.$vuetify.theme.dark
      },attrs:{"outlined":"","rounded":"lg"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-icon',{class:{
            'primary--text': _vm.activeBenefits[benefit.id],
            'grey--text': !_vm.activeBenefits[benefit.id],
          },attrs:{"left":""}},[_vm._v(_vm._s(benefit.icon))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(benefit.name))]),_c('v-spacer'),_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},model:{value:(_vm.activeBenefits[benefit.id]),callback:function ($$v) {_vm.$set(_vm.activeBenefits, benefit.id, $$v)},expression:"activeBenefits[benefit.id]"}})],1),_c('v-textarea',{attrs:{"label":"Descrição","rows":"2","dense":"","outlined":"","hide-details":"auto","disabled":!_vm.activeBenefits[benefit.id]},model:{value:(_vm.benefits[benefit.id].description),callback:function ($$v) {_vm.$set(_vm.benefits[benefit.id], "description", $$v)},expression:"benefits[benefit.id].description"}}),(benefit.hasTime)?_c('div',{staticClass:"d-flex mt-2 gap-4"},[_c('v-text-field',{attrs:{"label":"Horário Início","type":"time","dense":"","outlined":"","hide-details":"auto","disabled":!_vm.activeBenefits[benefit.id]},model:{value:(_vm.benefits[benefit.id].startAt),callback:function ($$v) {_vm.$set(_vm.benefits[benefit.id], "startAt", $$v)},expression:"benefits[benefit.id].startAt"}}),_c('v-text-field',{attrs:{"label":"Horário Fim","type":"time","dense":"","outlined":"","hide-details":"auto","disabled":!_vm.activeBenefits[benefit.id]},model:{value:(_vm.benefits[benefit.id].endAt),callback:function ($$v) {_vm.$set(_vm.benefits[benefit.id], "endAt", $$v)},expression:"benefits[benefit.id].endAt"}})],1):_vm._e()],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }