<template>
  <div class="pt-1">
    <v-text-field
      v-model="maxUses"
      label="Máximo de usos por cupom"
      hint="Deixe em branco para uso ilimitado"
      placeholder="Ilimitado"
      outlined
      type="number"
    ></v-text-field>

    <v-textarea
      v-model="codes"
      label="Códigos"
      hint="Um código por linha, mínimo de 4 caracteres"
      persistent-hint
      outlined
      :disabled="!!value?.id"
      :rules="[(v) => !!v || 'Campo obrigatório']"
    >
    </v-textarea>
    <v-alert type="info" text dense>
      {{ validCodes.length }} códigos válidos
    </v-alert>
    <v-alert type="warning" text dense>
      <strong>Atenção:</strong> Códigos duplicados serão removidos.
    </v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    maxUses: "",
    codes: "",
    validCodes: [],
  }),

  created() {
    if (this.value?.id && this.value?.Codes) {
      this.maxUses = this.value.Codes[0]?.maxUses || "";
      this.codes = this.value.Codes.map(c => c.code).join('\n');
    }
  },

  watch: {
    value: {
      handler(newValue) {
        if (newValue?.id && newValue?.Codes) {
          this.maxUses = newValue.Codes[0]?.maxUses || "";
          this.codes = newValue.Codes.map(c => c.code).join('\n');
        }
      },
      immediate: true
    },
    codes: {
      handler(val) {
        const codes = new Set(
          val.split("\n").map((code) =>
            code
              .trim()
              .toUpperCase()
              .replace(/[^A-Z0-9-_]/g, "")
          )
        );

        const arrayCodes = Array.from(codes);
        this.$nextTick(() => (this.codes = arrayCodes.join("\n")));
        this.validCodes = arrayCodes
          .filter((code) => code.length >= 4)
          .map((code) => ({ code, maxUses: this.maxUses }));

        this.$emit("input", {
          ...this.value,
          codes: this.validCodes,
        });
        this.$emit("update:valid", this.validCodes.length > 0);
      },
      immediate: true,
    },
  },
  props: {
    value: {
      type: Object,
      default: () => defaultData(),
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
