<template>
  <div
    :class="['party-cover-container', party?.holidayType?.toLowerCase()]"
    style="position: relative"
  >
    <canvas
      class="effects-container"
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        pointer-events: none;
        overflow: hidden;
      "
    >
    </canvas>
    <v-img class="rounded-xl" :src="party?.cover" :aspect-ratio="16 / 9">
      <template v-slot:placeholder>
        <v-card elevation="0" class="h-full">
          <v-skeleton-loader
            type="image"
            class="rounded-xl h-100"
            height="100%"
          />
        </v-card>
      </template>
    </v-img>

    <div style="position: absolute; top: 10px; left: 15px">
      <v-chip v-if="party?.private" color="info" label x-small>
        <v-icon x-small left>mdi-account-lock</v-icon>
        Privado
      </v-chip>
    </div>
    <div
      v-if="party?.youtubeId"
      style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%"
      class="d-flex align-center justify-center"
    >
      <v-btn fab x-large @click="playVideo" :loading="ytState.loading">
        <v-icon x-large>mdi-play</v-icon>
      </v-btn>
    </div>
    <div
      :id="`player-${id}`"
      class="rounded-xl"
      style="
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s;
        display: none;
      "
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    id: Math.random().toString(36).substr(2, 9),
    ytPlayer: null,
    showYtPlayer: false,
    ytState: {
      loading: true,
      ready: false,
      state: null,
    },
    timeout: null,
    timeoutDisplay: null,
    effectInterval: null,
    confettiConfig: {
      VALENTINES_DAY: {
        particles: [
          {
            type: "heart",
            size: 4,
          },
          {
            type: "heart",
            size: 2,
          },
        ],
        defaultColors: ["#FFC0CB", "#FF69B4", "#FF1493", "#C71585"],
        defaultDropRate: 1,
        particlesPerFrame: 0.1,
        windSpeedMax: 0.05,
        gravity: 0.05,
      },
      NEW_YEAR: {
        particles: [
          {
            type: "circle",
            size: 2,
            colors: ["#ffd54d"],
          },
          {
            type: "rect",
            size: 2,
            colors: ["#C0C0C0"],
          },
        ],
        defaultDropRate: 1,
        particlesPerFrame: 0.1,
        windSpeedMax: 1,
        gravity: 0.02,
      },
      CHRISTMAS: {
        particles: [
          {
            type: "circle",
            size: 1,
            colors: ["#ffffff"],
          },
          {
            type: "circle",
            size: 2,
            colors: ["#ffffff"],
          },
        ],
        defaultDropRate: 0.3,
        particlesPerFrame: 1,
        windSpeedMax: 0.4,
        gravity: 0.02,
      },
      HALLOWEEN: {
        particles: [
          {
            type: "circle",
            size: 2,
          },
          {
            type: "circle",
            size: 1,
          },
        ],
        defaultColors: ["#FF6B00", "#FF8400", "#000000", "#520380"],
        defaultDropRate: 1,
        particlesPerFrame: 0.1,
        windSpeedMax: 0.2,
        gravity: 0.1,
      },
      CARNIVAL: {
        particles: [
          {
            type: "rect",
            size: 4,
          },
          {
            type: "circle",
            size: 3,
          },
        ],
        defaultColors: ["#FF69B4", "#FFD700", "#7af57a", "#87CEEB", "#DDA0DD"],
        defaultDropRate: 1,
        particlesPerFrame: 0.2,
        windSpeedMax: 0.3,
        gravity: 0.08,
      },
      FESTA_JUNINA: {
        particles: [
          {
            type: "rect",
            size: 3,
          },
          {
            type: "rect",
            size: 3,
          },
        ],
        defaultColors: ["#1c2f75", "#f5d105", "#d10606", "#00821e"],
        defaultDropRate: 1,
        particlesPerFrame: 0.15,
        windSpeedMax: 0.25,
        gravity: 0.1,
      },
    },
  }),
  methods: {
    playVideo() {
      clearTimeout(this.timeout);
      this.showYtPlayer = true;
    },
    onYouTubeIframeAPIReady() {
      const player = new YT.Player(`player-${this.id}`, {
        videoId: this.party?.youtubeId,
        playerVars: {
          color: "white",
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onStateChange,
        },
      });
      this.ytPlayer = player;
    },
    onPlayerReady(event) {
      this.ytState.ready = true;
      this.ytState.loading = false;
    },
    onStateChange(event) {
      if (event.data == 1) this.playVideo();
      else if (event.data == 0) this.showYtPlayer = false;
      else if (event.data == 2)
        this.timeout = setTimeout(() => (this.showYtPlayer = false), 5000);
    },
    install() {
      if (!this.party?.youtubeId) return;
      this.ytState.loading = true;
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
      if (window.ytTag) {
        this.$nextTick(() => {
          return this.onYouTubeIframeAPIReady();
        });
      }
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      window.ytTag = tag;
      document.head.appendChild(tag);
    },
    uninstall() {
      window.onYouTubeIframeAPIReady = undefined;
      this.ytPlayer && this.ytPlayer.destroy();
      this.ytPlayer = null;
    },
    startHolidayEffect() {
      if (!this.party?.holidayType) return;

      const canvas = this.$el.querySelector(".effects-container");
      if (!canvas) return;

      const holidayType = this.party.holidayType;

      const config = this.confettiConfig[holidayType];
      if (!config) {
        console.warn("Tipo de feriado não encontrado:", this.party.holidayType);
        return;
      }

      this.$confetti.stop();

      setTimeout(() => {
        this.$confetti.start({
          ...config,
          canvasElement: canvas,
        });
      }, 100);
    },
  },
  watch: {
    showYtPlayer(val) {
      this.$emit("showVideo", val);
      if (val) {
        this.timeoutDisplay && clearTimeout(this.timeoutDisplay);
        this.ytPlayer.g.style.display = "block";
        this.ytPlayer.g.style.opacity = 100;
        this.ytPlayer.setVolume(100);
        this.ytPlayer.playVideo();
        setTimeout(() => (this.ytPlayer.g.style.display = "block"), 500);
      } else {
        this.ytPlayer.g.style.opacity = 0;
        this.timeoutDisplay = setTimeout(
          () => (this.ytPlayer.g.style.display = "none"),
          500
        );
      }
    },
    party: {
      handler() {
        this.showYtPlayer = false;
        this.uninstall();
        this.$nextTick(() => {
          this.install();
        });
      },
      deep: true,
    },
    "party.holidayType": {
      immediate: true,
      handler(newVal) {
        if (this.effectInterval) {
          clearInterval(this.effectInterval);
        }
        if (newVal) {
          this.$nextTick(() => {
            this.startHolidayEffect();
          });
        }
      },
    },
  },

  mounted() {
    this.install();
  },
  beforeDestroy() {
    if (this.effectInterval) {
      clearInterval(this.effectInterval);
    }
    this.uninstall();
    this.$confetti.stop();
  },

  props: {
    party: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.party-cover-container {
  position: relative;
  overflow: hidden;
}

.effects-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.v-image__image.v-image__image--cover {
  transition: background-image 0.3s ease-in-out;
}

/* Garante que os efeitos fiquem sobre outros elementos */
.preview-container .effects-container {
  z-index: 9999;
}
</style>
